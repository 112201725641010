/**
 * Object containing various color schemes categorized into continuous, diverging, and categorical types.
 * @constant
 * @property {string[]} continuous - Array of continuous color schemes.
 * @property {string[]} diverging - Array of diverging color schemes.
 * @property {string[]} categorical - Array of categorical color schemes.
 */
export const colorSchemes = {
    continuous: [
      "Blues",
      "BuGn",
      "BuPu",
      "GnBu",
      "Greens",
      "Greys",
      "OrRd",
      "PuBu",
      "PuBuGn",
      "PuRd",
      "Purples",
      "RdPu",
      "Reds",
      "YlGn",
      "YlGnBu",
      "YlOrBr",
      "YlOrRd",
    ],
    diverging: [
      "BrBG",
      "PiYG",
      "PRGn",
      "PuOr",
      "RdBu",
      "RdGy",
      "RdYlBu",
      "RdYlGn",
      "Spectral",
    ],
    categorical: [
      "Accent",
      "Dark2",
      "Paired",
      "Pastel1",
      "Pastel2",
      "Set1",
      "Set2",
      "Set3",
    ],
  };

export const colourSchemeSelectionColour = {
  "Blues": "red",
  "BuGn": "red",
  "BuPu": "green",
  "GnBu": "red",
  "Greens": "red",
  "Greys": "red",
  "OrRd": "green",
  "PuBu": "red",
  "PuBuGn": "red",
  "PuRd": "green",
  "Purples": "red",
  "RdPu": "green",
  "Reds": "green",
  "YlGn": "red",
  "YlGnBu": "red",
  "YlOrBr": "green",
  "YlOrRd": "green",
  "BrBG": "red",
  "PiYG": "blue",
  "PRGn": "red",
  "PuOr": "green",
  "RdBu": "green",
  "RdGy": "green",
  "RdYlBu": "green",
  "RdYlGn": "blue",
  "Spectral": "white",
  "Accent": "red",
  "Dark2": "red",
  "Paired": "red",
  "Pastel1": "red",
  "Pastel2": "red",
  "Set1": "yellow",
  "Set2": "red",
  "Set3": "red",
};